export const SYSTEM = 'system';
export const ADD_CREDIT_CARD = 'add_credit_card';
export const DELETE_CREDIT_CARD = 'delete_credit_card';
export const DELETE_API_TOKEN = 'delete_api_token';

export const FETCH_CREDIT_CARD = 'fetch_credit_card';
export const TRANSACTIONS_LIST = 'transactions_list';
export const DEPOSITS_LIST = 'deposits_list';
export const WITHDRAWS_LIST = 'withdraws_list'
export const EMAIL_AUTHENTICATION = 'email_authentication';
export const SETTINGS = 'settings';
export const USER_SCORES = 'user_scores';
export const COMMON_WALLETS_LIST = 'common_wallets_list';
export const API_TOKENS_LIST = 'api_tokens_list';

export const PHONE_AUTHENTICATION = 'phone_authentication';
export const INFO_AUTHENTICATION = 'info_authentication';
export const RESIDENTIAL_AUTHENTICATION = 'residential_authentication';
export const SELFIE_AUTHENTICATION = 'selfie_authentication';
export const WALLET_ONLINE_DEPOSIT = 'wallet_online_deposit';
export const WALLET_C2C_DEPOSIT = 'wallet_c2c_deposit';
export const WALLET_OFFLINE_DEPOSIT = 'wallet_offline_deposit';
export const WALLET_WITHDRAW = 'wallet_withdraw';
export const WALLET_PM_DEPOSIT = 'wallet_pm_deposit';
export const SUPPORT = 'support';
export const SHOPPING_BUY = 'shopping_buy';
export const SHOPPING_SELL = 'shopping_sell';
export const CHECKOUT = 'checkout';
export const TRANSACTION_INFO = 'transaction_info';
export const ADD_TOKEN = 'add_token';
export const LOGIN = 'login';
export const REGISTER = 'register';
export const FORGETPASSWORD = 'forgetPassword';
export const NOTICES = 'notices';
export const REFERRAL = 'referral';
export const ADD_WALLET_ADDRESS = 'add_wallet_address';
export const DELETE_WALLET_ADDRESS = 'delete_wallet_address'


