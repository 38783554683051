import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import CreditCard from './CreditCard';
import Authorization from './Authorization';
import Wallet from './Wallet';
import Support from './Support';
import Currency from "./Currency";
import Mail from "./Mail";
import Notice from "./Notice";
import Referral from "./Referral";
import LiveChat from "./LiveChat";
import ApiToken from "./ApiToken";


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  creditCard: CreditCard,
  authorization: Authorization,
  wallet: Wallet,
  support: Support,
  currency: Currency,
  mail: Mail,
  notice: Notice,
  referral: Referral,
  liveChat: LiveChat,
  apiToken: ApiToken

});
